.card {
  margin-top: 30px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.card-container {
  max-width: 1280px;
  margin-top: 100px;
  display: flex;
}

.card__image {
  width: 150px;
  height: 150px;
  -webkit-box-shadow: 0px 4px 22px -9px rgba(34, 60, 80, 0.3);
  -moz-box-shadow: 0px 4px 22px -9px rgba(34, 60, 80, 0.3);
  box-shadow: 0px 4px 22px -9px rgba(34, 60, 80, 0.3);
  border-radius: 20px;
}


.card__group {
  display: flex;
  flex-direction: column;
  margin-left: 150px;
}

.card__title {
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
  margin: 0;
  text-align: center;
}

.card__buy-button {
  color: white;
  margin-top: 30px;
  width: 350px;
  height: 40px;
  border-radius: 5px;
  background-color: #f08204;
  border: none;
}

.card__buy-button:hover {
  cursor: pointer;
  opacity: 0.7;
}

@media screen and (max-width: 768px) {
  .card__buy-button {
    width: 280px;
  }
.card__group {
  margin-left: 40px;
  }
}

@media screen and (max-width: 500px) {
  .card__buy-button {
    width: calc(100% - 20px);
  }

  .card-container {
    flex-direction: column;
    align-items: center;
    margin-top: 30px;

  }

  .card__group {
    margin: 20px 0 0 0;
    width: 100vw;
    align-items: center;
    justify-content: center;
  }

  .card-price {
    justify-content: center;
  }

  
  .card__title {
    font-size: 18px;
    font-weight: 500;
    line-height: 25px;
  }

  .card-price-n {
    font-size: 22px;
    font-weight: 600;
    line-height: 25px;
  }
}

