.feetback {
  max-width: 1280px;
  margin: auto;
}

.feetback-form {
  max-width: 396px;
  display: flex;
  flex-direction: column;
  margin: 0 auto 0 auto;
}

.input-data {
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: none;
  width: 396px;
  height: 17px;
  border-bottom: 1px solid #E8E8E8;
  padding: 0 0 10px 0;
  outline: none;
  margin-top: 5px;
}

.feetback__button-submit {
  width: 396px;
  margin: 25px 0 0 0;
  background: #000000;
  border-radius: 3px;
  color: #FFFFFF;
  padding: 13px 0 15px 0;
  color: #FFF;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border: none;
  background-color: #e6781e;
}

.feetback__form-title {
  color: #000;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 40px 0 40px 0;
}

.feetback_button-submit_status_disabled {
  background-color: #F8F8F8;
}

.feedback__info {
  margin: 25px 10px 0 10px;
}

@media screen and (max-width: 450px) {
  .feetback {
    align-items: center;
  }

  .feetback-form {
    margin: 0 10px 0 10px;
  }
  .feetback__input {
    width: 260px;
  }

  .feetback__input-error{
    width: 260px;
  }

  .feetback__button-submit {
    width: 100%;
    margin: 25px 0 0 0;
    border-radius: 3px;
    color: #FFFFFF;
    padding: 15px 0 15px 0;
    font-size: 12px;
  }

  .feetback__text-status {
    font-size: 12px;
  }

  .input-data {
    width: 100%;
  }

  .feedback__info {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
  }
}
