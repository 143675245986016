.menu-bottom {
  max-width: 1280px;
  margin: auto;
}

.menu-bottom__list {
  list-style: none;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 0;
  max-width: 1280px;
  margin: 20px 10px 0 10px;
  justify-content: center;
  gap: 5px;
}

.menu-bottom__link {
  text-decoration: none;
  height: 45px;
  display: flex;
  align-items: center;
  border-radius: 10px;
  background-color: rgb(232, 235, 238);
  justify-content: center;

}
.menu-bottom__text {
  margin: 0;
  color: rgb(0, 0, 0);
  font-weight: 500;
  margin: 0 10px 0 10px; 
  font-size: 16px; 
  white-space: pre-wrap;
}

.menu-bottom__link:hover {
  opacity: 0.8;
  cursor: pointer;
  background-color: rgb(240, 134, 14);
}