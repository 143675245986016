.not-found {
  display: flex;
  flex-direction: column;
  background-color: #FFFFFF;
}

.not-found__number {
  color: #000;
  text-align: center;
  font-size: 140px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 246px 0 0 0;
}
.not-found__title {
  color: #000;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 5px 0 0 0;
}

.not-found__button {
  background-color: transparent;
  border: none;
  margin: 184px 0 60px 0;
  color: #2BE080;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.not-found__button:hover {
  opacity: 0.7;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .not-found__number {
    font-size: 140px;
    margin: 246px 0 0 0;
  }

  .not-found__button {
    margin: 284px 0 222px 0;
  }
}

@media screen and (max-width: 320px) {
  .not-found__number {
    font-size: 80px;
    margin: 329px 0 0 0;
  }

  .not-found__title {
    font-size: 12px;
  }

  .not-found__button {
    margin: 284px 0 30px 0;
    color: #2BE080;
    font-size: 12px;
  }
}