.add-card {
  display: flex;
  flex-direction: column;
  gap: 5px;
  max-width: 1190px;
  margin:100px auto 0 auto;
  padding: 30px;
}

.add-card__btn-submit {
  margin-top: 50px;
  height: 60px;
  background-color: #e6781e;
  border: none;
  color: #EAEFF2;
  border-radius: 5px;
}

.add-card__btn-submit_status_disabled {
  background-color: #F8F8F8;
}

.add-card__inp-change {
  margin-bottom: 15px
}