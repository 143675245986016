.popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  align-items: flex-start;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.3s linear;
  display: flex;
  z-index: 7;
}

.popup_opened {
  visibility: visible;
  opacity: 1;
}

.popup__button-close {
  background-image: url('../../images/close_icon.svg');
  background-position: center;
  background-repeat: no-repeat;
  width: 22px;
  height: 22px;
  position: absolute;
  right: 15px;
  top: 15px;
  background-color: transparent;
  border: none;
}

.popup__button-close:hover {
  opacity: 0.8;
  cursor: pointer;
}