.count {
    display: flex;
    column-gap: 7px;
    align-items: center;
}

.count__input-cart {
	max-width: 45px;
    height: 30px;
	background-color: transparent;
    text-align: center;
    border: solid 2px green;
    padding: 0;
}

.product__price {
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.88px;
    display: flex;
    align-items: center;
}


.count__input::-webkit-inner-spin-button,
.count__input::-webkit-outer-spin-button {
    appearance: none;
}

.count__controls {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
}

.count__controls img {
    display: block;
}

.count__button-cart {
    width: 32px;
    height: 32px;
    padding: 0;
    border: none;
}

.count__button:hover{
  cursor: pointer;
}

.count__button-img {
    width: 100%;
  /* border-radius: 50%; */
}


@media screen and (max-width: 380px) {
    .count__input-cart {
        max-width: 30px;
        height: 30px;
  
    }
}
    