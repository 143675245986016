.product-catalog__group {
  min-width: 220px;
  display: flex;
  gap: 5px;
  flex-direction: column;
  overflow-y: hidden;
  overflow-x: auto;
  border-right: 2px solid rgb(204, 203, 203);
}

.group-container {
  display: flex;
}

.switch2__text {
  z-index: 6;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  margin: 0 0 0 5px;
}

.group-container {
  font-size: 8px;
  font-weight: 400;
  cursor: pointer;
}

/* = скрываем input */
.switch2 {
  display: none;
}

/* = стилизуем кнопку, которая работает вместе с input */
.stlradio {
  display: flex;
  width: 220px;
  height: 35px;
  position: relative;
  vertical-align: middle;
  box-sizing: border-box;
  align-items: center;
  margin-right: 4px;
  padding: 0 5px 0 5px;
  background-color:#ffffff;
  justify-content: center;
}

/* = делаем скрытую точку в нашем div */
.stlradio:before {
  content: '';
  background: #c2c2c2;
  width: 210px;
  height: 35px;
  opacity: 0;
  transition: 0.3s;
  position: absolute;
  top: 0px;
  left: 0px;
  border-radius: 7px;

  /* top: 2px;
  left: 2px; */
  /* border-radius: 300px; */
}

/* = включаем точку если input активен */
input:checked + .stlradio:before {
  opacity: 1;
}




@media screen and (max-width: 768px) {
  .product-catalog__group {
    flex-direction: row;
    border-right: none;
  }

  .group-container {
    flex-direction: column;
    margin-bottom: 10px;
  }

  .switch2__text {
    text-align: center;
    font-size: 12px;
    font-style: normal;
    margin: 0;
  }

  .switch2 {
    margin: 0;
  }

  .stlradio {
    border: 2px solid #b2b1b1;
    width: 90px;
    height: 45px;
    margin-right: 4px;
    padding: 0 5px 0 5px;
  }
  
  /* = делаем скрытую точку в нашем div */
  .stlradio:before {
    content: '';
    background: #37e06a;
    width: 86px;
    height: 41px;
    opacity: 0;
    transition: 0.3s;
    position: absolute;
    border-radius: 0;
  }

  .product-catalog__group::-webkit-scrollbar {
    width: 5px;
  }
  .product-catalog__group::-webkit-scrollbar-thumb {
      background: #919192;
      border-radius: 7px;
  }
  .product-catalog__group::-webkit-scrollbar-track-piece {
      background: #dddbdb;
      border-radius: 7px;
  }
}

@media screen and (max-width: 500px) {
  .product-catalog__group {
    padding: 0 10px;
    margin-bottom: 15px;
  }
}