.delivery__title {
  color: #000;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.88px;
  margin: 0;
}

.delivery__line {
  border-bottom: 1px solid #000;
  margin-top: 23px;
}

.delivery {
  width: -webkit-fill-available;
  max-width: 1280px;
  display: flex;
  flex-direction: column;
  margin: 30px auto 0 auto;
  padding: 0 50px;
}

.delivery__container {
  display: flex;
  flex-direction: column;
  column-gap: 40px;
  margin-top: 15px;
}

.delivery__text {
  margin: 2px;
}

@media screen and (max-width: 768px) {
  .delivery {
   
  }
}

@media screen and (max-width: 550px) {
  .delivery {
    padding: 0 10px 0 10px;
  }

  .delivery__container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    column-gap: 40px;
  }
}