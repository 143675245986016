.count {
    display: flex;
    column-gap: 7px;
    align-items: center;
}

.count__input {
	max-width: 35px;
    height: 30px;
	background-color: transparent;
    text-align: center;
    border: solid 2px green;
    padding: 0;
}

.count__input::-webkit-inner-spin-button,
.count__input::-webkit-outer-spin-button {
    appearance: none;
}

.count__controls {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
}

.count__controls img {
    display: block;
}

.count__button {
    width: 32px;
    height: 32px;
    padding: 0;
    border: none;
}

.count__button-img {
    width: 100%;
  /* border-radius: 50%; */
}

.count__button:hover {
  cursor: pointer;
}

@media screen and (max-width: 380px) {
  .count__button {
    width: 22px;
    height: 22px;
    margin-left: 5px;
  }

  .count__input {
    width: 22px;
    height: 22px;
  }

  .element__price {
    margin-left: 5px;
  }
}
  