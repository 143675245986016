.header__cart-anim {
  animation: loader-box 0.5s;
}

.header__cart-container {
  display: flex;
  justify-content: center;
  position: sticky;
  top: 0;
  z-index: 3;
  background-color: white;
  margin-bottom: 5px;
  width: 100%;
}

.header__cart {
  text-decoration: none;
  min-width: 130px;
  height: 48px;
  display: flex;
  align-items: center;  
  position: sticky;
  top: 5px;
}

.header__cart-img {
  width: 48px;
  height: 48px;
  margin-right: 10px;
}

.header__price-total {
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}

@media screen and (max-width: 500px) {
  .header__cart {
    margin-left: 40px;
  }
}