.auth {
  max-width: 396px;
  display: flex;
  flex-direction: column;
  margin: 0 auto 0 auto;
}

.auth__logo {
  /* background-image: url('../../images/logo.svg'); */
  width: 38px;
  height: 38px;
  margin-top: 70px;
}

.auth__logo:hover {
  opacity: 0.85;
}

.auth__title {
  color: #000;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 40px 0 40px 0;
}

.auth__hint {
  width: 100%;
  color: #A0A0A0;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.auth__input {
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: none;
  width: 396px;
  height: 17px;
  border-bottom: 1px solid #E8E8E8;
  padding: 0 0 10px 0;
  outline: none;
  margin-top: 5px;
}

.auth__input:invalid {
  color: #EE3465;
}

.auth__input:focus {
  font-weight: 500;
  border-bottom: 1px solid #2BE080;
}

.auth__form{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.auth__button-submit {
  width: 396px;
  margin: 109px 0 0 0;
  background: #000000;
  border-radius: 3px;
  color: #FFFFFF;
  padding: 13px 0 15px 0;
  color: #FFF;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border: none;
  background-color: #e6781e;
}

.auth__button-submit_status_disabled {
  background-color: #F8F8F8;
}

.auth__button-submit:hover {
  opacity: 0.85;
  cursor: pointer;
}

.auth__input-error {
  margin-top: 6px;
  min-height: 25px;
  width: 396px;
  display: flex;
  color: #EE3465;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}


.auth__input-error_active {
  visibility: visible;
}

.auth__link-auth {
  display: flex;
  justify-content: center;
  margin: 16px 0 70px 0;
}

.auth__text-status {
  margin: 0;
  color: #A0A0A0;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.signup__link {
  text-decoration: none;
  color: #e6781e;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.signup__link:hover {
  opacity: 0.7;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .auth__logo {
    margin-top: 232px;
  }
}

@media screen and (max-width: 450px) {
  .auth__logo {
    margin-top: 56px;
  }

  .auth {
    align-items: center;
  }

  .auth__input {
    width: 260px;
  }

  .auth__input-error{
    width: 260px;
  }

  .auth__button-submit {
    width: 260px;
    margin: 309px 0 0 0;
    border-radius: 3px;
    color: #FFFFFF;
    padding: 15px 0 15px 0;
    font-size: 12px;
  }

  .auth__text-status {
    font-size: 12px;
  }

  .signup__link{
    font-size: 12px;
  }
}
