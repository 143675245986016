/* @keyframes fadeOut {

  0% {transform: translateY(0px);}

  100% {transform: translateY(-30px);}

}
 */

.element {
  display: flex;
  flex-direction: column;
  width: 190px;
  align-items: center;
  -webkit-box-shadow: 0px 4px 22px -9px rgba(34, 60, 80, 0.3);
  -moz-box-shadow: 0px 4px 22px -9px rgba(34, 60, 80, 0.3);
  box-shadow: 0px 4px 22px -9px rgba(34, 60, 80, 0.3);
  border-radius: 5%;
  position: relative;
}

.element__order-info {
  position: absolute;
  height: 20px;
  width: 70px;
  background-color: aqua;
  bottom: 60px;
  right: 9px;
  font-size: 14px;
  display: none;
}

.scale-button {
  transform: scale(1.2);
}



.element:hover .element__image{
  cursor: pointer;
  /* transform: translate(0, -10px); */
  transform: scale(1.05);
}

.element:hover{
  cursor: pointer;
}

.element__image {
  padding: 0 16px 16px 16px;
  height: 150px;
  transition-duration: 0.5s;
}

.element__group {
  display: flex;  
  border-bottom: 1px solid #E8E8E8;
  justify-content: space-between;
  min-height: 45px;
  flex-direction: column;
  padding: 0 5px 16px 5px;
}

.element__title {
  margin: 0;
  color: var(--content-content-primary, #000);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  height: 32px;
  overflow: hidden;
}

.visible-none {
  display: none;
}

.element__price-block {
  margin-top: 10px;
  display: flex;
  align-items: center;
  gap: 25px;
  justify-content: space-between;
}

.element__buy-button {
  width: 32px;
  height: 32px;
  border: none;
  border-radius: 10%;
  padding: 0;
  /* background-color: #f08204;
  color: #E8E8E8;
  font-size: 25px;
  text-align: center; */
  transition: 0.3s;
}
.buy-button-img {
  width: 100%;
}


.element__buy-button_status_disabled {
  opacity: 0.4;
}

.element__price {
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
}

.element__buy-button:hover {
  cursor: pointer;
  /* opacity: 0.8; */
}

.element__edit-button {
  width: 30px;
  height: 30px;
}

.isShow {
  background-color: rgb(141, 199, 141);
}

@media screen and (max-width: 500px) {
  .element {
    width: 100%;
  }
}

@media screen and (max-width: 380px) {
   .element__title {
    margin-left: 5px;
  }
  .element__price-block {
    width: 145px;
    margin-left: 0 5px;
   }

   .element__image {
    padding: 0 0 5px 0;
    height: 120px;
   }

   .element__buy-button {
    width: 22px;
    height: 22px;
    margin-right: 5px;
   }
}

