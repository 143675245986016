.cart {
  max-width: 1280px;
  margin: 0 auto 0 auto;
  padding: 0 50px 0 50px;
}

.cart__container {
  /* display: grid;
  grid-template-columns: repeat(3, 3fr) 1fr;
  column-gap: 30px;
  padding: 22px 0; */
  background-color: #EAEFF2;
  /* justify-items: center;
  font-weight: 700;
  font-size: 13px;
  line-height: 1.2;
  letter-spacing: 0.2em;
  text-transform: uppercase; */
}

.cart__name {
  font-size: 18px;
  font-weight: 600;
}

.product {
  display: grid;
  grid-template-columns: repeat(3, 3fr) 1fr;
  column-gap: 30px;
  justify-items: center;
  padding: 8px 0;
  border-bottom: 1px solid rgba(126, 155, 189, 0.3);
}

.product__img {
  width: 100%;
}
.product__title-cart {
  display: flex;
  align-items: center;
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.88px;
  width: 100%;
  margin-left: 20px;
}

.product__btn-del {
  display: flex;
  align-items: center;
}

.cart__total {
  display: grid;
  grid-template-columns: repeat(3, 3fr) 1fr;
  column-gap: 30px;
  background-color: #EAEFF2;
  justify-items: center;
}
.cart__total-text{
  font-weight: 600;
  font-size: 18px;
  line-height: 1.3;
}

.cart__button-submit {
  width: 300px;
  height: 60px;
  margin: 50px auto 0 auto;
  background-color: #e6781e;
  border: none;
  color: #EAEFF2;
  border-radius: 10px;
  margin-bottom: 20px;
}
.cart__button-submit:hover {
  cursor: pointer;
  opacity: 0.8;
}

.cart-button-container {
  display: flex;
}

.cart__button-submit_status_disabled {
  background-color: #F8F8F8;
}

.input-error {
  margin-top: 2px;
  min-height: 25px;
  max-width: 396px;
  display: flex;
  color: #EE3465;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.order-info {
  display: flex;
  flex-direction: column;
  max-width: 700px;
  margin-top: 30px;
}

.order-info__text {
  margin: 3px 15px 0 0;
  min-width: 180px;

}

.order-info__input {
  padding-left: 20px;
  background-color: #EAEFF2;
  border: 2px #9fcde7 solid;
  border-radius: 20px;
  height: 30px;
}

.order-info__title {
  color: #000;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.88px;
  margin: 0 0 15px 0;
}

.cart__container-bottom {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
}
.product__controls {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .cart {
    padding: 0 30px 0 30px;
  }

  .product__title-cart {
    font-size: 17px;
    margin: 0;
  }
  .product__price {
    font-size: 17px;
  }

  .cart__total-text {
    font-size: 15px;
    width: 100%;
    text-align: center;
  }

  .cart__container-bottom {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  .product {
    /* grid-template-columns: repeat(3, 3fr) 1fr; */
    grid-template-columns: 110px 3fr 3fr 1fr;
    column-gap: 20px;
  }

  .cart__total {
    display: grid;
    grid-template-columns: 155px 3fr 3fr 1fr;
  }
}

@media screen and (max-width: 500px) {
  .cart {
    padding: 0 10px 0 10px;
  }
  .cart__name {
    font-size: 14px;
  }
  .product__title-cart {
    font-size: 14px;
  }
  .product__price {
    font-size: 14px;
  }

  .input-error {
    max-width: 500px;
  }


  .product {
    /* grid-template-columns: repeat(3, 3fr) 1fr; */
    grid-template-columns: 3fr 3fr 3fr 1fr;
    column-gap: 20px;
  }

  .cart__total {
    display: grid;
    grid-template-columns: 120px 3fr 3fr 1fr;
  }

}

@media screen and (max-width: 365px) {
  .cart__total {
    grid-template-columns: 110px 3fr 3fr;
  }

  .product__btn-del {
   width: 30px;
  }
}