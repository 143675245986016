.slick-next {
  background-color: rgb(230, 115, 8);
  width: 40px;
  height: 40px;
  right: 20px;
  border-radius: 50%;
}

.slick-prev {
  background-color: rgb(230, 115, 8);
  width: 40px;
  height: 40px;
  left: 20px;
  border-radius: 50%; z-index: 2;
}


.slick-dots li.slick-active button:before
{
    opacity: .75;
    color: rgb(238, 146, 9);
    font-size: 12px;
}


.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus
{
    color: rgb(230, 115, 8);
    outline: none;
    background: rgb(230, 115, 8);
}

.slider {
  max-width: 1280px;
  margin: 40px auto 0px auto;
}

.slider__container {
  display: flex;
  max-width: 100%;
  /* height: 200px; */
}

.slider__img {
  width: 100%;
  overflow: hidden;
}

.slider__img:hover {
  opacity: 0.8;
  cursor: pointer;
}

.slider__price {
  text-align: center;
}

.slider__element-title {
  text-align: center;
}

.slider__title {
  color: #000;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.88px;
}

.slider__line {
  border-bottom: 1px solid #000;
  margin-top: 23px;
  margin-bottom: 50px;
}
.slider__more-details {
  width: calc(100% - 80px);
  height: 40px;
  margin: 0 auto 0 auto;
  color: aliceblue;
  background-color: rgb(120, 168, 168);
  border: none;
  border-radius: 5px;
}

.slider__more-details:hover {
  opacity: 0.8;
  cursor: pointer;
}



.slider__btn-container {
  display: flex;
  align-items: center;
}


/* .slick-slide {
  margin-right: 25px;
}
 */
@media screen and (max-width: 768px) {
  .slider {
    max-width: 1280px;
    padding: 0 50px 0 50px;
  }

  .slider__img-container {
    height: 230px;
  }
}

@media screen and (max-width: 500px) {
  .slider__img-container {
    height: 250px;
  }
  
  .slider {
    max-width: 1280px;
    padding: 0 10px 0 10px;
    margin-top: 20px;
  }

  /* .slick-next {
    width: 10px;
    height: 10px;
    right: 20px;
  }
  
  .slick-prev {
    width: 10px;
    height: 10px;
    left: 10px;
  } */


  .slick-next {
    width: 24px;
    height: 24px;
    right: 8px;
    border-radius: 50%;
  }
  
  .slick-prev {
    width: 24px;
    height: 24px;
    left: 8px;
    border-radius: 50%; z-index: 2;
  }
  .slick-prev:before {
    font-size: 12px
  }
  .slick-next:before {
    font-size: 12px
  }
}