.edit-card {
  display: flex;
  flex-direction: column;
  max-width: 1300px;
  padding: 10px;
  margin: auto;
  gap: 15px;
}

.edit-card__btn-submit {
  margin-top: 50px;
  height: 60px;
  background-color: #e6781e;
  border: none;
  color: #EAEFF2;
  border-radius: 5px;
}

.edit-card__btn-del {
  margin-top: 50px;
  height: 60px;
  background-color: #e6461e;
  border: none;
  color: #EAEFF2;
  border-radius: 5px;
}

.edit-card__btn:hover {
  cursor: pointer;
  opacity: 0.8;
}

.edit-card__data {
  padding: 15px;
  display: flex;
  flex-direction: column;
  border: #e6781e 1px solid;
  flex-wrap: wrap;
}

.input-title {
  margin-top: 15px;
}

.checkbox {
  margin-bottom: 15px;
}

