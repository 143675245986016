.footer {
  display: flex;
  background-color: #cacaca;
  flex-direction: column;
  min-height: 100px;
  width: 100%;
  margin-top: 50px;
  align-items: center;
  justify-content: center;
}

.footer__text {
  color: aliceblue;
}

@media screen and (max-width: 500px) {
  .footer {
    min-height: 100px;
  }
}