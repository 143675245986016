.popup-info__content {
  display: flex;
  position: relative;
  width: 430px;
  height: 250px;
  margin: auto;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 15px;
  flex-direction: column;
}

.popup-info__massage {
  margin: 20px;
}
  
.popup-info__close {
  border-radius: 10px;
  margin-top: 30px;
  width: 250px;
  height: 40px;
  color: #FFF;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border: none;
  background-color: #f08204;  
}
