.form-search__input {
  width: 100%;
  height: 46px;
  border-radius: 40px;
  background: #F9F9F9;
  border: none;
  text-indent: 22px;
}

.form-search__input_placeholder::placeholder {
  color: red;
}
