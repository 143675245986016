.contacts {
  max-width: 1280px;
  display: flex;
  flex-direction: column;
  margin: 50px auto 0 auto;
  padding: 0 50px 0 50px;
  width: -webkit-fill-available;
}

.contacts__title {
  color: #000;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.88px;
  margin: 0;
}

.contacts__line {
  border-bottom: 1px solid #000;
  margin-top: 23px;
  margin-bottom: 15px;
}

.contacts__info {
  font-style: normal;
  font-weight: 500;
  margin: 0;
  text-decoration: none;
}

@media screen and (max-width: 768px) {
  .contacts {
    padding: 0 50px 0 50px;
  }
}

@media screen and (max-width: 500px) {
  .contacts {
    padding: 0 10px 0 10px;
  }
  .contacts__info {
    font-size: 14px;
  }
}