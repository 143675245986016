@keyframes snow {
  0% {background-position: 0px 0px, 0px 0px, 0px 0px;}
  100% {background-position: 500px 1000px, 400px 400px, 300px 300px;}
 }


.header {
  display: flex;
  position: relative;
  min-height: 100px;
  padding: 0 30px;
  justify-content: center;
  border-bottom: #afabab 1px solid;
  color: #252525;
}

.header__container {
  display: flex;
  width: 1280px;
  justify-content: space-between;
}

.header__logo-img {
  width: 100%;
}

.header__logo {
  width: 240px;
  margin-right: 15px;
  background-repeat: no-repeat;
}

.header__logo:hover {
  opacity: 0.8;
  cursor: pointer;
}

.header__profile {
  display: flex;
  align-items: center;
  gap: 17px;
  justify-content: center;
}



.header__link-registration {
  text-align: right;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-decoration: none;
  line-height: 16px
}

.header__link-registration:hover {
  opacity: 0.8;
}

.header__link-login {
  width: 76px;
  height: 32px;
  border-radius: 3px;
  background: #2BE080;
  color: #000;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  border: none;
  color: aliceblue;
}

.header__link-login:hover {
  opacity: 0.8;
  cursor: pointer;
}

.header__info {
  text-decoration: none;
  text-align: center;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
}

.header__social-block {
  display: flex;
  align-items: center;
}


.header__icon {
  margin-left: 10px;
  width: 30px;
  height: 30px;
}

.header__contact-block {
 display: flex;
 align-items: center;
 justify-content: center;
}

.header__container2 {
  display: none;
}

.header__wholesale {
  text-decoration: none;
  margin-left: 15px;
}

@media screen and (max-width: 880px) {
  .header__container {
    display: none
  }

  .header__container2 {
    display: block;
    width: calc(100% - 60px);
  }

  .header__social-block {
    justify-content: space-between;
    margin-top: 15px;
  }
}

@media screen and (max-width: 570px) {
  .header {
    padding: 0 10px;
  }

  .header__link-registration {
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px
  }

  .header__link-login {
    width: 64px;
    height: 26px;
    border-radius: 3px;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    color: aliceblue;
  }

  .header__info {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    margin: 3px 0 3px 0;
  }

  .header__container2 {
    width: 100%;
    /* background-image: url(../../images/snow.png);
    animation: snow 50s linear infinite; */
  } 

  .header__contact-block {
    justify-content: center;
  }

  .header__logo {
    width: 180px;
  }

  .header__info-open {
    font-size: 14px;
  }

/* .header__info_disabled {
  display: none;
} */
}