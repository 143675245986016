.product-catalog {
  display: flex;
  max-width: 1280px;
  flex-direction: column;
  margin: 50px auto 0 auto;
  padding: 0 50px 0 50px;
}
.product-catalog__menu-container {
  display: flex;
  align-items: center;
}

.product-catalog__menu {
  display: flex;
  align-items: center;
  min-width: 220px;
  height: 48px;
  background-color: #e6781e;
  border-radius: 10px;
  justify-content: center;

}
.product__title {
  color: rgb(255, 255, 255);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.88px;
  margin: 0;
  text-align: center;
}

.product__line {
  border-bottom: 1px solid #000;
  margin-top: 23px;
}

.product__container {
  display: flex;
  margin-top: 20px;
}

.product-catalog__item {
  margin: 0 0 0 30px;
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

}

.product-catalog-find {
  width: 100%;
  min-height: 300px;
}

.product-catalog__item:hover {
  cursor: pointer;
}

.movie-elements_visible {
  visibility: hidden;
}

.elements-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: 20px;
  row-gap: 20px;
  margin-top: 25px;
  padding: 0;
}

.product-catalog__button-yet {
  width: 320px;
  height: 36px;
  margin: 20px auto 20px auto;
  color: #ffffff;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 10px;
  background-color: #e6781e;
  border: none;
}

.product-catalog__button-yet:hover {
  opacity: 0.8;
  cursor: pointer;
}

.product-catalog__button-yet_visible_no {
  visibility: hidden;
}

.form-search {
  width: calc(100% - 50px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  align-items: center;
  margin: 0 20px 0 30px;
}


.form-search__button-submit {
  position: absolute;
  height: 34px;
  width: 80px;
  right: 6px;
  background-image: url('../../images/find.svg');
  background-repeat: no-repeat;
  border: none;
  background-color: #F9F9F9;
}

.form-search__button-submit:hover {
  opacity: 0.8;
  cursor: pointer;
}

.product-catalog-find {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cancel-search {
  width: 90px;
  height: 44px;
  border-radius: 15px;
  border: none;
  font-size: 12px;
  color: rgb(26, 26, 26);
  /* background-color: #2BE080;
  color: aliceblue; */
}

.cancel-search:hover {
  cursor: pointer;
  opacity: 0.7;
}

@keyframes loader-box {
  0% {
    transform: scale(1)
  }
  50% {
    transform: scale(1.15)
  }
  100% {
    transform: scale(1)
  }
}

@media screen and (max-width: 768px) {
  .product-catalog {
    padding: 0 30px 0 30px;
  }
  .product__container {
    flex-direction: column;
  }
  
  .product-catalog__menu {
    display:none;
  }
  .product-catalog__menu-container {
    flex-direction: column-reverse;
    align-items: center;
    row-gap: 5px;
  }

  .cancel-search {
    display: none;
  }
}

@media screen and (max-width: 500px) {
  .product-catalog {
    padding: 0 10px 0 10px;
    margin-top: 30px;
  }
  
  .movie-elements__button-yet {
    width: 240px;
  }
  .elements-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
    margin-top: 0;
  }
  .form-search {
    width: 100%;
    margin: 0 10px 0 10px;
  }

  .header__cart {
    margin-left: 40px;
  }

  .product-catalog__button-yet {
    width: 300px;
  }
}