.main-menu__list {
  margin: 30px auto 0 auto; 
  list-style: none;
  display: flex;
  gap: 20px;
  padding: 0;
  justify-content: center;
  padding: 0 10px;
}

.main-menu__item {
  text-decoration: none;
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 123.077% */
  text-decoration-line: underline;
}

@media screen and (max-width: 500px) {

}
